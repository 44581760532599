import { useTranslation } from 'react-i18next';
import { footerTrans } from 'translations';
import { Container } from 'react-bootstrap';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <section className="mt-5 bg-light">
      <Container className="py-5 lh-sm">
        <p>
          <small>
            <strong>{t(footerTrans.heading)}</strong>
          </small>
        </p>
        <p>
          <small>{t(footerTrans.body)} </small>
        </p>
      </Container>
    </section>
  );
};

export default Footer;
