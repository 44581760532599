export const breakpoints = {
  1: {
    slidesPerView: 3,
  },
  500: {
    slidesPerView: 4,
  },
  768: {
    slidesPerView: 5,
  },
  992: {
    slidesPerView: 8,
  },
  1200: {
    slidesPerView: 10,
  },
  1520: {
    slidesPerView: 14,
  },
};
