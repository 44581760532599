import useAccessoriesPrice from 'hooks/useAccessoriesPrice';
import { useSelector } from 'react-redux';
import useExtraPackagesPrice from 'hooks/useExtraPackagesPrice';

const useTotalPrice = () => {
  const { builderData } = useSelector(state => state.data);
  const { specialPrice, price, exteriors, interiors, wheels: wheelsData } = builderData;
  const { colorCode, interior, wheels } = useSelector(state => state.config);

  const accessoriesPrice = useAccessoriesPrice();
  const extraPackagesPrice = useExtraPackagesPrice();

  if (!price || !exteriors || !interiors) return 0;

  const findItemPrice = (data, key, code) => {
    const foundItems = data?.filter(item => item?.[key] === code);
    if (!!foundItems.length) return foundItems?.[0]?.price || 0;
    return 0;
  };

  return (
    (specialPrice || price) +
    accessoriesPrice +
    extraPackagesPrice +
    findItemPrice(exteriors, 'code', colorCode) +
    findItemPrice(interiors, 'code', interior) +
    findItemPrice(wheelsData, 'code', wheels)
  );
};

export default useTotalPrice;