import { Button } from 'react-bootstrap';
import { ReactComponent as Rewind } from 'assets/images/icons/Rewind.svg';
import LocalizedLink from 'components/LocalizedLink';

/**
 * Rewind button component
 * @param href - string, given to Link component
 * @param text - string, given to button for text
 * @returns {JSX.Element}
 * @constructor
 */
const RewindButton = ({ href, text }) => {
  return (
    <LocalizedLink to={href}>
      <Button variant="link" className="btn-icon-left">
        <Rewind />
        {text}
      </Button>
    </LocalizedLink>
  );
};

export default RewindButton;
