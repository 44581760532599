import { Col, Container, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import { breakpoints } from 'components/AssemblySliderSelectors/carouselConfig';
import Swatch from 'components/Swatch';

import { ReactComponent as Arrow } from 'assets/images/icons/ArrowLeft.svg';

const FeatureCarousel = ({ activeItem, setActiveItem, data, placeholderImage }) => {
  if (!data) return null;

  return (
    <Container className="bg-white py-4 px-5">
      <Row>
        <Col xs md="3" lg="2">
          <p className="fw-bold mb-1">{data.filter(item => item?.code === activeItem)[0]?.name}</p>
          <p className="mb-0">{`${
            data.filter(item => item?.code === activeItem)[0]?.price || 0
          }€`}</p>
        </Col>
        <Col xs md="9" lg="10" className="d-flex">
          <div className="swiper-nav-btn swiper-prev" role="button" aria-label="Previous slide">
            <Arrow />
          </div>
          <Swiper
            breakpoints={breakpoints}
            navigation={{
              prevEl: '.swiper-prev',
              nextEl: '.swiper-next',
            }}
          >
            {!data.categories &&
              data
                .filter(item => !!item)
                .map((radio, idx) => (
                  <SwiperSlide key={`swatch-${idx}`}>
                    <Swatch
                      img={radio.pictures?.thumb || placeholderImage}
                      name={radio.name}
                      value={radio.code}
                      selectedValue={activeItem}
                      setSelected={() => setActiveItem(radio.code)}
                    />
                  </SwiperSlide>
                ))}
            {data.categories &&
              data.categories.map((category, catIdx) =>
                category.options.map((radio, swatchIdx) => (
                  <SwiperSlide
                    key={`swatch-${category.name}-${swatchIdx}`}
                    className={
                      catIdx !== data.categories.length - 1 &&
                      swatchIdx === category.options.length - 1
                        ? 'bordered'
                        : ''
                    }
                  >
                    <Swatch
                      img={radio.img}
                      name={radio.name}
                      value={radio.code}
                      selectedValue={activeItem}
                      setSelected={() => setActiveItem(radio.code)}
                      categoryName={category.name}
                      showCategory={swatchIdx === 0}
                    />
                  </SwiperSlide>
                ))
              )}
          </Swiper>
          <div className="swiper-nav-btn swiper-next" role="button" aria-label="Next slide">
            <Arrow />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default FeatureCarousel;