import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  gradeId: null,
  model: null,
  specification: null,
  engine: null,
  body: null,
  colorCode: null,
  wheels: null,
  interior: null,
  extraPackages: [],
  accessories: [],
};

const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setModel: (state, { payload }) => {
      state.model = payload;
    },
    setSpecification: (state, { payload }) => {
      state.specification = payload;
    },
    setEngine: (state, { payload }) => {
      state.engine = payload;
    },
    setBody: (state, { payload }) => {
      state.body = payload;
    },
    setColor: (state, { payload }) => {
      state.colorCode = payload;
    },
    setWheels: (state, { payload }) => {
      state.wheels = payload;
    },
    setInterior: (state, { payload }) => {
      state.interior = payload;
    },
    addAccessory: (state, { payload }) => {
      state.accessories.push(payload);
    },
    removeAccessory: (state, { payload }) => {
      state.accessories = state.accessories.filter(item => item !== payload);
    },
    addExtraPackage: (state, { payload }) => {
      state.extraPackages.push(payload);
    },
    removeExtraPackage: (state, { payload }) => {
      state.extraPackages = state.extraPackages.filter(item => item !== payload);
    },
    selectGrade: (state, { payload }) => {
      const { modelId, specEic, gradeId } = payload;
      state.model = modelId;
      state.specification = specEic;
      state.gradeId = gradeId;
    },
    setConfig: (state, { payload }) => ({ ...state, ...payload }),
    resetConfig: state => initialState,
  },
});

export const {
  setModel,
  setEngine,
  setBody,
  setColor,
  setWheels,
  setInterior,
  selectGrade,
  addAccessory,
  removeAccessory,
  addExtraPackage,
  removeExtraPackage,
  setConfig,
  setSpecification,
  resetConfig,
} = slice.actions;

export default slice.reducer;
