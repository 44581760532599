import { useDispatch } from 'react-redux';
import { Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { resetConfig, selectGrade } from 'store/entities/config';
import { resetBuilderData } from 'store/entities/data';
import { resetAppState } from 'store/entities/app';

import 'components/Cards/GradeCard/styles.scss';
import LocalizedLink from 'components/LocalizedLink';

export const GradeCard = ({ gradeData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { grade, eic, model } = gradeData;

  const handleGradeSelect = () => {
    dispatch(resetConfig());
    dispatch(resetBuilderData());
    dispatch(resetAppState());
    dispatch(selectGrade({ modelId: model?.id, specEic: eic, gradeId: grade.id }));
  };

  return (
    <Card className="grade-card mb-4">
      <Card.Img variant="top" src={model?.pictures?.main} />
      <Card.Body className="pb-0">
        <Card.Title className="fw-bold mb-sm">{grade?.name} </Card.Title>
        <Card.Text>
          <span className="d-block mb-0">{`${gradeData?.body?.name}${
            gradeData?.body?.doors ? `, ${gradeData?.body?.doors} ust` : ''
          }`}</span>
          <small>{t('starting_price', { price: `${gradeData?.price} €` })}</small>
        </Card.Text>
        <hr />
        <div className="d-grid gap-2">
          <LocalizedLink to="/select">
            <Button variant="primary" onClick={handleGradeSelect}>
              {t('grade_select_button')}
            </Button>
          </LocalizedLink>
        </div>
      </Card.Body>
    </Card>
  );
};
