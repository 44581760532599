import React, { useState } from 'react';
import { Button, CloseButton } from 'react-bootstrap';

import BottomBarBasic from 'components/BottomBar/BottomBarBasic';
import CartInformation from 'components/BottomBar/CartInformation';
import { useDisableBodyScroll } from 'hooks/useDisableBodyScroll';
import useBreakPoints from 'hooks/useBreakPoints';

import { ReactComponent as ArrowUp } from 'assets/images/icons/ArrowUp.svg';

import './style.scss';

const BottomBar = ({ action }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { isXs, isSmallerThanLg } = useBreakPoints();
  useDisableBodyScroll(isExpanded);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  if (!isSmallerThanLg) return null;

  return (
    <>
      <div className={`bottom-bar ${isExpanded ? 'bottom-bar-expanded' : ''}`}>
        <div className="bottom-bar-content">
          <Button className="btn-round expander-btn" onClick={toggleExpand}>
            <ArrowUp />
          </Button>
          <BottomBarBasic isExpanded={isExpanded} handleCart={toggleExpand} action={action} />
          <CartInformation />
          {isXs && <CloseButton onClick={toggleExpand} />}
        </div>
      </div>
      {isExpanded && <div className="bottom-bar-overlay" />}
    </>
  );
};

export default BottomBar;
