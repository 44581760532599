import React from 'react';
import { Col, Row } from 'react-bootstrap';

import SearchInput from 'components/Accessories/SearchInput';

import {
  AccessoriesFilterSelectionDesktop,
  AccessoriesFilterSelectionMobile,
} from 'components/Accessories/AccessoriesFilterSelection';
import useBreakPoints from 'hooks/useBreakPoints';

const AccessoriesFilter = () => {
  const { isSmallerThanLg } = useBreakPoints();

  return (
    <Row className="mt-4">
      <Col xs={12} lg={7}>
        {!isSmallerThanLg ? (
          <AccessoriesFilterSelectionDesktop />
        ) : (
          <AccessoriesFilterSelectionMobile />
        )}
      </Col>
      <Col xs={12} lg={5}>
        <SearchInput />
      </Col>
    </Row>
  );
};

export default AccessoriesFilter;
