import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Collapse, ListGroup } from 'react-bootstrap';
import AccessoryListItem from 'components/StickyAside/AccessoryListItem';
import useAccessoriesPrice from 'hooks/useAccessoriesPrice';
import useAllAccessories from 'hooks/useAllAccessories';
import { getSelectedAccessory } from 'utils/accessoryUtils';

import { ReactComponent as ArrowDown } from 'assets/images/icons/ArrowDown.svg';

const SelectedActions = () => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();
  const { accessories } = useSelector(state => state.config);

  const allAccessories = useAllAccessories();
  const accessoriesPrice = useAccessoriesPrice();

  if (!accessories.length) return null;

  return (
    <>
      <ListGroup.Item className="bg-light">
        <div
          className={`selected-accessories-toggle ${
            open ? 'selected-accessories-toggle-open' : ''
          }`}
          onClick={() => setOpen(!open)}
        >
          <div className="d-flex align-items-center">
            <ArrowDown />
            <p>{t('selected_accessories')}</p>
            <span className="selected-accessories-count">
              <span>{accessories.length}</span>
            </span>
          </div>
          <span className="fw-bold">{accessoriesPrice} €</span>
        </div>
        <Collapse in={open}>
          <div className="selected-accessories-list">
            {accessories.map(accessory => (
              <React.Fragment key={`acc-${accessory}`}>
                {getSelectedAccessory(allAccessories, accessory, AccessoryListItem, {
                  key: 'id',
                })}
              </React.Fragment>
            ))}
          </div>
        </Collapse>
      </ListGroup.Item>
    </>
  );
};

export default SelectedActions;
