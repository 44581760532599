import React from 'react';
import { Badge } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const FiltersBadge = () => {
  const { filters, selectedCategories } = useSelector(state => state.app);

  if (!filters.length && selectedCategories === 'all') return null;

  return <Badge>{filters.length + (selectedCategories !== 'all' ? 1 : 0)}</Badge>;
};

export default FiltersBadge;
