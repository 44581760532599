import { useDispatch } from 'react-redux';
import { removeAccessory, removeExtraPackage } from 'store/entities/config';
import { Button, ListGroup } from 'react-bootstrap';
import { ReactComponent as Close } from 'assets/images/icons/Close.svg';

const AccessoryListItem = ({ data, type }) => {
  const dispatch = useDispatch();
  const handleRemoveAccessory = id => {
    type === 'extraPackage' ? dispatch(removeExtraPackage(id)) : dispatch(removeAccessory(id));
  };

  return (
    <ListGroup.Item className="d-flex justify-content-between align-items-start fw-bold">
      {data.name}
      <div className="d-flex align-items-center">
        <span>{data.price}€</span>
        <Button variant="link" onClick={() => handleRemoveAccessory(data?.id)}>
          <Close />
        </Button>
      </div>
    </ListGroup.Item>
  );
};

export default AccessoryListItem;
