import { useSelector } from 'react-redux';

const useExtraPackagesPrice = () => {
  const { builderData } = useSelector(state => state.data);
  const { extraPackages } = useSelector(state => state.config);
  const { options } = builderData;

  let price = 0;
  extraPackages.forEach(selectedPackage => {
    const foundItem = options.find(item => item.id === selectedPackage);
    if (!!foundItem) price += foundItem.price;
  });
  return price;
};

export default useExtraPackagesPrice;
