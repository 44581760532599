import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import axios from 'axios';

import ThreeSixty from 'components/360Viewer/index';
import AltCarImage from 'views/Assemble/AltCarImage';

const ThreeSixtyWrapper = () => {
  const [pictures, setPictures] = useState([]);

  const { colorCode, wheels } = useSelector(state => state.config);
  const { builderData } = useSelector(state => state.data);
  const { eic } = builderData;

  const { refetch: fetchData } = useQuery(
    ['360pictures', eic, colorCode, wheels],
    async () =>
      axios.get(`/builder/combined/${eic}`, {
        params: {
          'options.code': [colorCode, ...(!!wheels ? [wheels] : [])],
        },
      }),
    {
      enabled: false,
      onSuccess: res => {
        const pics = res.data.pictures['360'];
        setPictures(!!pics ? pics : []);
      },
    }
  );

  useEffect(() => {
    if (!!colorCode) {
      fetchData();
    }
  }, [colorCode, fetchData, wheels]);

  if (pictures.length) {
    return (
      <ThreeSixty
        key={pictures}
        amount={32}
        autoplay
        images={pictures}
        buttonClass="button"
        spinReverse
      />
    );
  } else {
    return <AltCarImage />;
  }
};

export default ThreeSixtyWrapper;
