import { ReactComponent as Wheel } from 'assets/images/icons/Wheel.svg';

export const tabsData = [
  {
    id: 0,
    title: 'exterior_color',
    dataKey: 'exteriors',
  },
  {
    id: 1,
    title: 'wheels',
    dataKey: 'wheels',
  },
  {
    id: 2,
    title: 'interior',
    dataKey: 'interiors',
  },
];

export const iconCardData = {
  icon: <Wheel className="text-primary" />,
  link: 'testdrive_button',
  href: 'https://www.toyota.ee/testdrive',
};
