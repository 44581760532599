import React, { useState } from 'react';
import { Collapse, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getSelectedAccessory } from 'utils/accessoryUtils';
import AccessoryListItem from 'components/StickyAside/AccessoryListItem';
import useExtraPackagesPrice from 'hooks/useExtraPackagesPrice';

import { ReactComponent as ArrowDown } from 'assets/images/icons/ArrowDown.svg';

const SelectedExtraPackages = () => {
  const [open, setOpen] = useState(false);

  const extraPackagesPrice = useExtraPackagesPrice();
  const { t } = useTranslation();
  const { extraPackages } = useSelector(state => state.config);
  const { builderData } = useSelector(state => state.data);

  const { options } = builderData;

  if (!extraPackages.length) return null;

  return (
    <>
      <ListGroup.Item className="bg-light">
        <div
          className={`selected-accessories-toggle ${
            open ? 'selected-accessories-toggle-open' : ''
          }`}
          onClick={() => setOpen(!open)}
        >
          <div className="d-flex align-items-center">
            <ArrowDown />
            <p>{t('selected_packages')}</p>
            <span className="selected-accessories-count">
              <span>{extraPackages.length}</span>
            </span>
          </div>
          <span className="fw-bold"> {extraPackagesPrice} €</span>
        </div>
        <Collapse in={open}>
          <div className="selected-accessories-list">
            {extraPackages.map(item => (
              <React.Fragment key={`acc-${item}`}>
                {getSelectedAccessory(options, item, AccessoryListItem, {
                  key: 'id',
                  type: 'extraPackage',
                })}
              </React.Fragment>
            ))}
          </div>
        </Collapse>
      </ListGroup.Item>
    </>
  );
};

export default SelectedExtraPackages;
