import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import axios from 'axios';
import useSalesFrontConfig from 'hooks/useSalesFrontConfig';
import useSelectedAccessory from 'hooks/useSelectedAccessory';

const OfferForm = ({ handleStepChange }) => {
  const { t } = useTranslation();
  const salesfrontConfig = useSalesFrontConfig();
  const { gradeId } = useSelector(state => state.config);
  const { locations, offerModalType } = useSelector(state => state.app);
  const { builderData } = useSelector(state => state.data);
  const { register, handleSubmit, watch, setValue } = useFormContext();
  const { engine, body } = useSelectedAccessory();

  const { model: builderModel, grades } = builderData;

  const location = watch('location');

  const { mutate } = useMutation('offer-form', async data => axios.post(`/customers/lead`, data), {
    onSuccess: () => handleStepChange('finish'),
  });

  useEffect(() => {
    if (location === '1' && !!locations.length) {
      setValue('location', locations[0].shortName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createTestDriveDescription = () =>
    `Test drive, "${builderModel.name}", "${
      grades?.find(item => item.id === gradeId)?.name || ''
    }", "${engine.name}", "${body.name}"`;

  const createOfferDescription = () => `${builderData?.name} - request for offer`;

  const handleFormSubmit = data => {
    const { firstName, lastName, email, phoneNr, location } = data;

    const payload = {
      location,
      source: window.location.href,
      description:
        offerModalType === 'offer' ? createOfferDescription() : createTestDriveDescription(),
      customer: {
        firstName,
        lastName,
        phoneNumber: phoneNr,
        email,
      },
      config: salesfrontConfig,
      createActivity: offerModalType === 'offer',
    };
    handleStepChange('loading');
    mutate(payload);
  };

  return (
    <form onSubmit={handleSubmit(data => handleFormSubmit(data))}>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="firstName" className="required">
          {t('form_first_name')}
        </Form.Label>
        <Form.Control
          id="firstName"
          name="firstName"
          {...register('firstName', { required: true })}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="lastName" className="required">
          {t('form_last_name')}
        </Form.Label>
        <Form.Control id="lastName" name="lastName" {...register('lastName', { required: true })} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="lastName" className="required">
          {t('form_email')}
        </Form.Label>
        <Form.Control
          type="email"
          id="email"
          name="email"
          {...register('email', { required: true })}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="phoneNr" className="required">
          {t('form_phone_number')}
        </Form.Label>
        <Form.Control
          type="phone"
          id="phoneNr"
          name="phoneNr"
          {...register('phoneNr', { required: true })}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="location">{t('form_location')}</Form.Label>
        <Form.Select {...register('location')}>
          {locations.map(item => (
            <option key={item.shortName} value={item.shortName}>
              {item.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Button type="submit">{t('submit_button')}</Button>
    </form>
  );
};

export default OfferForm;
