import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormControl, InputGroup } from 'react-bootstrap';

import { ReactComponent as Search } from 'assets/images/icons/Search.svg';
import { setSearchValue } from 'store/entities/app';

const SearchInput = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { searchValue } = useSelector(state => state.app);

  const handleChange = e => {
    const inputValue = e.target.value;
    dispatch(setSearchValue(inputValue));
  };

  return (
    <InputGroup className="search-input">
      <FormControl
        placeholder={t('search')}
        value={searchValue}
        aria-label="Search"
        onChange={handleChange}
      />
      <Button variant="link">
        <Search />
      </Button>
    </InputGroup>
  );
};

export default SearchInput;
