import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Nav, Navbar, Container, Badge } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { navTrans } from 'translations';
import { ReactComponent as ChevronLeft } from 'assets/images/icons/ChevronLeft.svg';
import './styles.scss';
import LocalizedLink from 'components/LocalizedLink';

const NavBar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { builderData } = useSelector(state => state.data);
  const { model } = builderData;

  const showBackButton = useMemo(() => !location.pathname.includes('/grade'), [location.pathname]);

  return (
    <Navbar collapseOnSelect expand="lg" variant="dark">
      <Navbar.Brand
        as={LocalizedLink}
        to={model ? `/grade?id=${model?.id}` : '/grade'}
        className="ms-3"
      >
        {showBackButton && (
          <>
            <ChevronLeft />
            <span className="d-none d-lg-inline">
              <small>{t('header_back')}</small>
            </span>
          </>
        )}
      </Navbar.Brand>
      <Container>
        <Navbar.Toggle aria-controls="responsive-navbar-nav">
          <p className="text-muted mb-2">
            <small>{navTrans.step} </small>
          </p>
          <span>{navTrans.temp} </span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mx-auto">
            {navTrans.links.map((link, idx) => (
              <LocalizedLink
                key={`navlink-${idx}`}
                to={link.href}
                type="navlink"
                className="mx-3 d-flex align-items-center"
              >
                <Badge pill bg="dark" className="me-2 d-none d-lg-grid">
                  <small>{idx + 1}</small>
                </Badge>
                {t(link.label)}
              </LocalizedLink>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
