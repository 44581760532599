import { useState } from 'react';
import { Collapse, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReactComponent as ArrowDown } from 'assets/images/icons/ArrowDown.svg';
import RecommendedPackagesList from 'components/RecommendedPackagesList';

const SelectedPackages = () => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();
  const { extraPackages } = useSelector(state => state.config);

  if (!extraPackages.length) return null;

  return (
    <ListGroup.Item className="borderless">
      <div
        className={`selected-accessories-toggle ${open ? 'selected-accessories-toggle-open' : ''}`}
        onClick={() => setOpen(!open)}
      >
        <div className="d-flex align-items-center justify-content-between py-2">
          <h5 className="mb-0">{t('selected_packages') + `(${extraPackages.length})`}</h5>
          <ArrowDown />
        </div>
      </div>
      <Collapse in={open}>
        <div className="mb-4">
          <RecommendedPackagesList showSelected />
        </div>
      </Collapse>
    </ListGroup.Item>
  );
};

export default SelectedPackages;
