import { Col, Image, Row } from 'react-bootstrap';

const InteriorImage = ({ src }) => {
  return (
    <Row>
      <Col xs={12} className="d-flex justify-content-center align-items-center interior-image">
        <Image src={src} />
      </Col>
    </Row>
  );
};

export default InteriorImage;
