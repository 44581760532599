import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setEngine } from 'store/entities/config';
import { CarPartCard } from 'components/Cards/CarPartCard';

export const EngineCard = ({ engineData, handleInfo }) => {
  const { engine } = useSelector(state => state.config);
  const { id, name, price, type } = engineData;

  const dispatch = useDispatch();
  const handleSelectEngine = () => {
    dispatch(setEngine(id));
  };

  const isActive = useMemo(() => engine === id, [engine, id]);

  return (
    <CarPartCard
      title={name + ' ' + type}
      price={price}
      handleClick={handleSelectEngine}
      handleInfo={handleInfo}
      buttonText="choose_engine_button"
      isActive={isActive}
    />
  );
};
