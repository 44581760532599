import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  specificationsData: [],
  rawBuilderData: {},
  builderData: {},
  bodyData: {},
};

const slice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setSpecificationsData: (state, { payload }) => {
      state.specificationsData = payload.reduce((res, input) => {
        res[input.grade.id] = res[input.grade.id] || [];
        res[input.grade.id].push(input);
        return res;
      }, {});
    },
    setRawBuilderData: (state, { payload }) => {
      state.rawBuilderData = payload;
    },
    setBuilderData: (state, { payload }) => {
      const { accessories= [], techdata = [], wheels = [] } = payload;
      let newPayload = { ...payload };
      newPayload.accessories = accessories.reduce((obj, el) => {
        obj[el.categoryName] = [...(obj[el.categoryName] || []), el];
        return obj;
      }, {});
      newPayload.techdata = techdata.reduce((obj, el) => {
        obj[el.categoryName] = [...(obj[el.categoryName] || []), el];
        return obj;
      }, {});
      const defaultWheels = wheels.find(item => !item.code);
      newPayload.wheels = [defaultWheels, ...wheels.filter(item => !!item.code)];
      state.builderData = newPayload;
    },
    setBodyData: (state, { payload }) => {
      const mappedData = {};
      payload.forEach(item => {
        mappedData[item.body.id] = { ...(mappedData[item.body.id] || item.body) };
        mappedData[item.body.id].engines = [...(mappedData[item.body.id].engines || [])];
        mappedData[item.body.id].engines.push(item.engine);
      });
      state.bodyData = mappedData;
    },
    resetBuilderData: state => {
      return { ...initialState, specificationsData: state.specificationsData };
    },
  },
});

export const {
  setSpecificationsData,
  setRawBuilderData,
  setBuilderData,
  setBodyData,
  resetBuilderData,
} = slice.actions;

export default slice.reducer;
