import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Col, Image, Row } from 'react-bootstrap';

import PlaceholderCar from 'assets/images/placeholder-car.png';

const AltCarImage = () => {
  const { colorCode } = useSelector(state => state.config);
  const { builderData } = useSelector(state => state.data);

  const { exteriors } = builderData || {};

  const exteriorImages = useMemo(() => {
    const found = exteriors?.find(item => item.code === colorCode);
    return found || {};
  }, [colorCode, exteriors]);

  return (
    <Row>
      <Col xs={12} className="d-flex justify-content-center">
        <Image className="alt-car-img" src={exteriorImages?.pictures?.main || PlaceholderCar} />
      </Col>
    </Row>
  );
};

export default AltCarImage;