import { Form, Accordion } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import './styles.scss';
import { addExtraPackage, removeExtraPackage } from 'store/entities/config';

const RecommendedPackagesList = ({ showSelected = false }) => {
  const dispatch = useDispatch();
  const { builderData } = useSelector(state => state.data);
  const { extraPackages } = useSelector(state => state.config);

  const { options = [] } = builderData;

  const handleChange = (id, isChecked) =>
    isChecked ? dispatch(removeExtraPackage(id)) : dispatch(addExtraPackage(id));

  return (
    <Accordion defaultActiveKey="0">
      {options.map((item, idx) => {
        const isChecked = extraPackages.includes(item.id);
        if (showSelected && !isChecked) return null;

        return (
          <Accordion.Item key={`acc-${idx}`} eventKey={`acc-${idx}`}>
            <div className="d-flex justify-content-between align-items-start pt-3">
              <div className="me-auto">
                <p className="fw-bold mb-0">{item.name}</p>
                <p>
                  <small>{item.price}€</small>
                </p>
              </div>
              <div>
                <Form.Check
                  className="d-flex justify-content-end"
                  type="checkbox"
                  id={`package-${idx}`}
                  checked={isChecked}
                  onChange={() => handleChange(item.id, isChecked)}
                />
              </div>
            </div>
          </Accordion.Item>
        );
      })}
    </Accordion>
  );
};

export default RecommendedPackagesList;
