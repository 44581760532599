/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { saveAs } from 'file-saver';
import axios from 'axios';

import LoadingSpinner from 'components/LoadingSpinner';
import { setModalState } from 'store/entities/app';

export const PdfModal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [savedConfigId, setSavedConfigId] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [hasError, setHasError] = useState(false);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { pdfModal: isOpen } = useSelector(state => state.app);
  const { config } = useSelector(state => state);

  const { refetch: fetchPdf } = useQuery(
    'downloadPdf',
    async () => {
      const res = await axios.get('/builder/config/pdf', {
        params: {
          configId: savedConfigId,
          language: i18n.language,
        },
        responseType: 'arraybuffer',
      });
      return res.data;
    },
    {
      enabled: false,
      onSuccess: res => {
        const blob = new Blob([res], { type: 'application/pdf' });
        setPdf(blob);
        setIsLoading(false);
      },
      onError: () => {
        setIsLoading(false);
        setHasError(true);
      },
    }
  );

  const { mutate: saveConfig } = useMutation(
    'saveConfigForPdf',
    async () =>
      axios.post(`/builder/config`, {
        config: {
          ...config,
        },
      }),
    {
      enabled: false,
      onSuccess: res => {
        const configId = res?.data?.configId;
        setSavedConfigId(configId);
      },
      onError: () => setHasError(true),
    }
  );

  const handleClose = () => {
    setHasError(false);
    if (isLoading) return;
    dispatch(setModalState({ modal: 'pdfModal', newState: false }));
  };

  useEffect(() => {
    if (isOpen) {
      if (!savedConfigId && !isLoading) {
        setIsLoading(true);
        saveConfig();
      } else {
        setIsLoading(true);
        fetchPdf();
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (!!savedConfigId && isLoading) fetchPdf();
  }, [savedConfigId]);

  const handlePdf = (type = 'download') => {
    if (type === 'download') {
      saveAs(pdf, `${t('configuration_result')}.pdf`);
    } else {
      const fileURL = window.URL.createObjectURL(pdf);
      const tab = window.open();
      tab.location.href = fileURL;
    }
  };

  return (
    <Modal show={isOpen} size="lg" onHide={handleClose}>
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>{t(`pdf_modal_title`)}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-5">
        {isLoading && <LoadingSpinner />}
        {hasError && <p>{t('pdf_modal_error')}</p>}
        {!!pdf && !isLoading && (
          <div>
            <h2 className="mb-4 fs-3">{t('pdf_modal_success')}</h2>
            <div className="d-flex flex-column flex-md-row gap-4">
              <Button onClick={() => handlePdf()}>{t('btn_download')}</Button>
              <Button onClick={() => handlePdf('open')} variant="light">
                {t('btn_open')}
              </Button>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
