import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filters: [],
  selectedCategories: 'all',
  searchValue: '',
  selectedTab: 0,
  pdfModal: false,
  saveConfigModal: false,
  offerModal: false,
  offerModalType: 'offer',
  locations: [],
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    addFilter: (state, { payload }) => {
      state.filters = [...state.filters, payload];
    },
    removeFilter: (state, { payload }) => {
      state.filters = state.filters.filter(item => item !== payload);
    },
    setSelectedCategory: (state, { payload }) => {
      state.selectedCategories = payload;
    },
    setSearchValue: (state, { payload }) => {
      state.searchValue = payload;
    },
    setSelectedTab: (state, { payload }) => {
      state.selectedTab = payload;
    },
    setModalState: (state, { payload }) => {
      const { modal, newState, type } = payload;
      if (type) state.offerModalType = type;
      if (state.hasOwnProperty(modal)) {
        state[modal] = newState;
      }
    },
    setLocations: (state, { payload }) => {
      state.locations = payload;
    },
    resetAppState: state => {
      state = { ...initialState, locations: state.locations };
    },
  },
});

export const {
  addFilter,
  removeFilter,
  setSearchValue,
  resetAppState,
  setSelectedTab,
  setModalState,
  setLocations,
} = slice.actions;

export default slice.reducer;
