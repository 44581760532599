import { ListGroup } from 'react-bootstrap';

export const AccessoryListGroupItem = ({ data, type }) => {
  return (
    <ListGroup.Item
      className={`d-flex justify-content-between align-items-start ${
        type === 'conclusion' ? '' : 'bg-light'
      }`}
    >
      {data?.name}
      <span className={`${type === 'conclusion' ? '' : 'fw-bold'}`}>{data?.price || 0}€</span>
    </ListGroup.Item>
  );
};

export const AccessoryListItem = ({ data, valueKey }) => <li>{data[valueKey]}</li>;

const defaultOptions = {
  key: 'code',
  valueKey: 'name',
  type: 'conclusion', // conclusion | assembly | extraPackage
};

export const getSelectedAccessory = (data, code, ReturnComponent, options = {}) => {
  const { key, valueKey, type } = { ...defaultOptions, ...options };
  const foundItems = data?.filter(item => item?.[key] === code);

  if (!ReturnComponent) return foundItems;

  if (!!foundItems?.length) {
    return <ReturnComponent data={foundItems[0]} valueKey={valueKey} type={type} />;
  }

  return null;
};