import React from 'react';
import { Col, Row } from 'react-bootstrap';

import AccessoriesFilter from 'components/Accessories/AccessoriesFilter';

import './style.scss';
import AccessoriesList from 'components/Accessories/AccessoriesList';

const Accessories = () => {
  return (
    <Row className="mt-4">
      <Col xs={12}>
        <AccessoriesFilter />
      </Col>
      <Col xs={12}>
        <AccessoriesList />
      </Col>
    </Row>
  );
};

export default Accessories;
