import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SwiperCore, { Navigation } from 'swiper';

import FeatureCarousel from 'components/AssemblySliderSelectors/FeatureCarousel';
import { setColor, setInterior, setWheels } from 'store/entities/config';
import { setSelectedTab } from 'store/entities/app';
import { tabsData } from 'data';
import PlaceholderWheels from 'assets/images/placeholder-alloy-wheel.png';
import PlaceholderColor from 'assets/images/placeholder-color.png';
import PlaceholderInterior from 'assets/images/placeholder-interior.png';

import './carousel.scss';
import './tab.scss';

SwiperCore.use([Navigation]);

const AssemblySliderSelectors = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { colorCode, interior, wheels } = useSelector(state => state.config);
  const { builderData } = useSelector(state => state.data);
  const { selectedTab } = useSelector(state => state.app);

  useEffect(() => {
    if (!colorCode && builderData?.exteriors?.length) {
      dispatch(setColor(builderData?.exteriors[0].code));
    }

    if (!interior && builderData?.interiors?.length) {
      dispatch(setInterior(builderData?.interiors[0].code));
    }

    if (!wheels && builderData?.wheels?.length) {
      dispatch(setWheels(builderData?.wheels?.code || undefined));
    }
  }, [
    builderData?.exteriors,
    builderData?.interiors,
    builderData?.wheels,
    colorCode,
    dispatch,
    interior,
    wheels,
  ]);

  return (
    <ul className="justify-content-center nav-pills nav nav-tabs">
      {tabsData.map(({ title, id }) => (
        <li className="nav-item" key={`tab-${id}`}>
          <button
            className={`nav-link ${selectedTab === id ? 'active' : ''}`}
            onClick={() => dispatch(setSelectedTab(id))}
          >
            {t(title)}
          </button>
        </li>
      ))}
      {selectedTab === 0 && (
        <FeatureCarousel
          activeItem={colorCode}
          setActiveItem={code => dispatch(setColor(code))}
          data={builderData.exteriors}
          placeholderImage={PlaceholderColor}
        />
      )}
      {selectedTab === 1 && (
        <FeatureCarousel
          activeItem={wheels}
          setActiveItem={code => dispatch(setWheels(code))}
          data={builderData.wheels}
          placeholderImage={PlaceholderWheels}
        />
      )}
      {selectedTab === 2 && (
        <FeatureCarousel
          activeItem={interior}
          setActiveItem={code => dispatch(setInterior(code))}
          data={builderData.interiors}
          placeholderImage={PlaceholderInterior}
        />
      )}
    </ul>
  );
};

export default AssemblySliderSelectors;