import { Image, ToggleButton } from 'react-bootstrap';
import { ReactComponent as Check } from 'assets/images/icons/Check.svg';
import './styles.scss';

const Swatch = ({
  name,
  img,
  value,
  selectedValue,
  setSelected,
  categoryName,
  showCategory = false,
}) => {
  const isSelected = value === selectedValue;

  return (
    <>
      <ToggleButton
        key={name}
        id={value || name.replace(/\s/g, '')}
        name={name}
        value={value}
        variant="light"
        type="radio"
        className="color-swatch"
        checked={isSelected}
        onChange={() => setSelected(value || undefined)}
      >
        <div className={`swatch ${isSelected ? 'active' : ''}`}>
          <p className="swatch-text mb-0">{showCategory && <small>{categoryName}</small>}</p>
          <Image src={img} />
          {isSelected && <Check />}
        </div>
      </ToggleButton>
    </>
  );
};

export default Swatch;
