import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'react-bootstrap';

import { ReactComponent as Cart } from 'assets/images/icons/Cart.svg';
import useTotalPrice from 'hooks/useTotalPrice';

const BottomBarBasic = ({ handleCart, action, isExpanded }) => {
  const { t } = useTranslation();
  const price = useTotalPrice();

  return (
    <div className="bottom-bar-basic">
      <div className="bottom-bar-basic-info">
        {!isExpanded && (
          <Button onClick={handleCart} className="btn-round btn-round-large cart-btn">
            <Cart />
          </Button>
        )}
        <div className="d-flex flex-column">
          <span>{t('your_car')}</span>
          <span>
            <strong>{`${price}€`}</strong>
          </span>
        </div>
      </div>
      {action}
    </div>
  );
};

export default BottomBarBasic;
