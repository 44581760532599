import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import axios from 'axios';

import { Container, Row } from 'react-bootstrap';

import ConfigLoadingPlaceholder from 'views/Config/ConfigLoadingPlaceholder';
import ConfigFailedLoading from 'views/Config/ConfigFailedLoading';
import LoadingSpinner from 'components/LoadingSpinner';
import { setConfig } from 'store/entities/config';
import { setBuilderData } from 'store/entities/data';
import { useTranslation } from 'react-i18next';

const Config = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isConfigLoading, setIsConfigLoading] = useState(true);
  const [fetchedConfig, setFetchedConfig] = useState(null);

  const { id } = queryString.parse(location.search);

  const saveBuilderData = data => {
    dispatch(setBuilderData(data));
    navigate(`/${i18n.language}/conclusion`);
  };

  const { refetch: fetchBuilderData } = useQuery(
    'builder-data',
    async () =>
      axios.get(`/builder/combined/${fetchedConfig.specification}`, {
        params: {
          'options.code': [
            fetchedConfig.colorCode,
            ...(!!fetchedConfig.wheels ? [fetchedConfig.wheels] : []),
          ],
          language: `${i18n.language}_LV`,
        },
      }),
    {
      enabled: false,
      onSuccess: res => saveBuilderData(res.data),
      onError: () => setIsConfigLoading(false),
    }
  );

  const saveSpecification = res => {
    if (!res.data) return;
    const { config } = res.data;
    dispatch(setConfig(config));
    setFetchedConfig(config);
  };

  const { refetch: fetchData } = useQuery(
    'config data',
    async () => axios.get(`/builder/config/${id}`),
    {
      enabled: false,
      onSuccess: res => saveSpecification(res),
      onError: () => setIsConfigLoading(false),
    }
  );

  useEffect(() => {
    if (fetchedConfig) fetchBuilderData();
  }, [fetchBuilderData, fetchedConfig]);

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id, fetchData]);

  return (
    <>
      {isConfigLoading && <LoadingSpinner />}
      <Container style={{ minHeight: '70vh' }}>
        <Row className="mt-5">
          {isConfigLoading ? <ConfigLoadingPlaceholder /> : <ConfigFailedLoading />}
        </Row>
      </Container>
    </>
  );
};

export default Config;
