import React, { useState } from 'react';
import { Button, Collapse, OverlayTrigger } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import FiltersBadge from 'components/Accessories/FiltersBadge';
import { addFilter, removeFilter } from 'store/entities/app';

import { ReactComponent as Filter } from 'assets/images/icons/Filter.svg';
import { ReactComponent as Close } from 'assets/images/icons/Close.svg';
import { useTranslation } from 'react-i18next';

const AccessoriesFilterSelection = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { filters } = useSelector(state => state.app);
  const { builderData } = useSelector(state => state.data);
  const { accessories } = builderData || {};

  const toggleFilter = (isFilterActive, filter) =>
    isFilterActive ? dispatch(removeFilter(filter)) : dispatch(addFilter(filter));

  return (
    <div className="accessories-filter" {...props}>
      <p className="fw-bold">{t('accessories_filter_category')}</p>
      {Object.keys(accessories || {}).map(key => {
        const isFilterActive = filters.includes(key);

        return (
          <p
            key={`filter-${key}`}
            role="button"
            aria-pressed={isFilterActive}
            onClick={() => toggleFilter(isFilterActive, key)}
            className={`accessories-filter-item ${
              isFilterActive ? 'accessories-filter-item-active' : ''
            }`}
          >
            {key}
            {isFilterActive && <Close />}
          </p>
        );
      })}
    </div>
  );
};

export const AccessoriesFilterSelectionDesktop = () => {
  const { t } = useTranslation();
  return (
    <OverlayTrigger
      placement="bottom"
      trigger="click"
      overlay={AccessoriesFilterSelection()}
      rootClose
    >
      <div className="filter-trigger">
        <Button variant="link" className="btn-icon-left">
          <Filter /> {t('accessories_filter_default')}
        </Button>
        <FiltersBadge />
      </div>
    </OverlayTrigger>
  );
};

export const AccessoriesFilterSelectionMobile = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="accessories-filter-mobile mb-3">
      <div className="filter-trigger">
        <Button
          variant="link"
          className="btn-icon-left"
          onClick={() => setIsOpen(!isOpen)}
          aria-expanded={isOpen}
          aria-controls="filters-collapse-content"
        >
          <Filter /> {t('accessories_filter_default')}
        </Button>
        <FiltersBadge />
      </div>
      <Collapse in={isOpen}>
        <AccessoriesFilterSelection id="filters-collapse-content" />
      </Collapse>
    </div>
  );
};

export default AccessoriesFilterSelection;
