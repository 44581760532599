import { Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Info } from 'assets/images/icons/Info.svg';

import 'components/Cards/CarPartCard/styles.scss';

export const CarPartCard = ({
  title,
  info,
  price,
  icon,
  handleClick,
  buttonText,
  isActive,
  handleInfo,
}) => {
  const { t } = useTranslation();

  return (
    <Card className={`car-part-card mb-4 ${isActive ? 'active' : ''}`}>
      <Card.Body>
        <Card.Title className="fw-bold mb-sm">{title} </Card.Title>
        <div className="d-flex justify-content-between align-items-start">
          <div className="me-auto pe-3">
            <p>{info}</p>
            <span className="size-5">{price}</span>
          </div>
          {icon && (
            <div className="text-center">
              {icon.component ? <icon.component /> : null}
              <p className="text-uppercase fst-italic fw-bolder">{icon.text}</p>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-between">
          <Button variant="link" className="btn-icon-left" onClick={handleInfo}>
            <Info />
            {t('additional_info')}
          </Button>
          <Button
            variant={isActive ? 'light' : 'primary'}
            onClick={handleClick}
            disabled={isActive}
          >
            {t(buttonText)}
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};
