import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AccessoriesCategory from 'components/Accessories/AccessoriesCategory';

const AccessoriesList = () => {
  const { t } = useTranslation();
  const { builderData } = useSelector(state => state.data);
  const { filters } = useSelector(state => state.app);

  const { accessories } = builderData || {};

  return (
    <>
      <h3 className="mt-4">{t('accessories_list_title')}</h3>
      <p>{t('accessories_list_subtitle')}</p>
      <div className="consent">
        <h5>{t('consent_accessories_title')}</h5>
        <p>{t('consent_accessories_text')}</p>
      </div>
      {!!accessories &&
        Object.keys(accessories)
          .filter(item => (filters.length > 0 ? !!filters.includes(item) : true))
          .map(category => (
            <AccessoriesCategory
              key={category}
              category={category}
              data={accessories[category]}
              hasMargin
            />
          ))}
    </>
  );
};

export default AccessoriesList;
