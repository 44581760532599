import { CarPartCard } from 'components/Cards/CarPartCard';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { setBody } from 'store/entities/config';

export const BodyCard = ({ bodyData, handleInfo }) => {
  const { body } = useSelector(state => state.config);
  const { id, name, style } = bodyData;

  const dispatch = useDispatch();
  const handleBodySelect = () => {
    dispatch(setBody(id));
  };

  const isActive = useMemo(() => body === id, [body, id]);

  return (
    <CarPartCard
      title={name}
      info={style}
      isActive={isActive}
      handleClick={handleBodySelect}
      handleInfo={handleInfo}
      buttonText="choose_body_button"
    />
  );
};
